import React from 'react'

// App components
import BracesExamples from '../../components/BracesExamples'
import Button from '../../components/Button'
import FeatureTable from '../../components/FeatureTable'
import ImageAndText from '../../components/ImageAndText'
import Layout from '../../components/layout'
import PageTitle from '../../components/PageTitle'
import Promo from '../../components/Promo'

// App content
import grantAndPatients from '../../assets/patientPics/grant-and-patients.jpg'

// App utils
import { rhythm } from '../../utils/typography'
import { themeStyles, colors, metrics } from '../../utils/theme'
import downArrow from '../../assets/down-arrow.png'
import '../../styles/invisalign.css'

class Page extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <div css={themeStyles.contentWidth}>
          <div id="header-div" className="header-div">
            <h1 id="invisalign-h1" className="invisalign-h1">
              Ready to start Invisalign?
              <br />
              Watch this
            </h1>
            <img
              id="invisalign-down-arrow-img"
              src={downArrow}
              alt="down arrow"
              className="down-arrow"
            />
          </div>
          <div className="video-background">
            <iframe
              width="250"
              height="150"
              src="https://www.youtube.com/embed/-ybYoxMC3oo?si=Gq4Kciyn2FIsbvgt"
              title="Collins Orthodontics Invisalign Tips & Tricks"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          <BracesExamples selectedIndexs={[2]} />
          <h2 css={[themeStyles.blueTitleText, themeStyles.innerTextBreak]}>
            Why is Invisalign a Great Choice for Orthodontic Treatment?
          </h2>
          <ImageAndText image={grantAndPatients} focus={'40% 10%'}>
            <p>
              Honestly, one of the most common reasons why people do not want to
              straighten their teeth is because they do not want to wear
              traditional metal braces and wires. The great news is that,
              because of invisalign, you no longer have to! Invisalign treatment
              at Collins Orthodontics consists of a series of clear, removable
              aligners that are custom-modeled to fit your teeth. The virtually
              invisible aligners gradually straighten your teeth over time, and
              unlike traditional braces, invisalign has no metal bands or wires!
              Most people will not even be able to tell you are getting your
              teeth straightened!
            </p>
            <Button
              to="/schedule-a-free-consult"
              text="Schedule Your FREE Consult"
              style={{ marginTop: rhythm(1) }}
            />
          </ImageAndText>
          <h2
            css={[
              themeStyles.blueTitleText,
              themeStyles.innerTextBreak,
              themeStyles.elementMargin,
            ]}
          >
            Why You Need to Consider Invisalign Treatment
          </h2>
          <p
            css={{
              ...themeStyles.innerTextBreak,
              textAlign: 'center',
              ...themeStyles.elementMargin,
              ...themeStyles.textPadding,
            }}
          >
            With invisalign you can have a straighter, healthier smile without
            all the noticeable wires and brackets of traditional braces.
            Invisalign helps improve your overall oral health by making it
            easier to brush and floss, while also maintaining the proper tooth
            structure. Invisalign aligners are removable, making it easy for you
            to enjoy the foods you love without worrying about breaking your
            appliance.
          </p>
          <br />
          <p css={{ ...themeStyles.innerTextBreak, textAlign: 'center' }}>
            You can also remove your invisalign aligners to brush and floss
            regularly, so you don’t need to change anything about your current
            lifestyle! With invisalign you can continue participating in
            day-to-day activities without changing a thing. Invisalign is great
            for professionals, athletes, brides, and parents. Office visits and
            checkups are easy, quick, and painless. You will also need to
            schedule fewer appointments throughout your entire treatment.
          </p>
          <div
            css={{
              backgroundColor: colors.brightBlue,
              ...themeStyles.elementMargin,
              ...themeStyles.textPadding,
              paddingBottom: metrics.defaultPadding,
            }}
          >
            <h2
              css={[
                themeStyles.blueTitleText,
                themeStyles.innerTextBreak,
                themeStyles.elementMargin,
                {
                  color: 'white',
                  marginBottom: metrics.defaultMargin,
                  marginTop: 0,
                },
              ]}
            >
              Invisalign is made to fit your lifestyle
            </h2>
            <FeatureTable
              rows={[
                'Clear, Virtually Invisible Aligners',
                'Removable, Will Not Get In The Way Of Your Activities',
                'Easy To Remove To Eat, Brush, And Floss',
                'Graduate To A New Set Of Aligners Once Every Week',
                'Easy To Monitor Your Progress With Regular Appointments',
              ]}
            />
          </div>
          <Promo />
        </div>
      </Layout>
    )
  }
}

export default Page
